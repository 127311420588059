import { useEffect } from 'react'

interface DialogComponentInterface{
  innerComponenet : (setDialogClose : ()=>void) => JSX.Element,
  open : boolean,
  setOpen : (a:boolean) => void
}

function DialogComponent(p : DialogComponentInterface) {


  const handleDialogClose = () => {
    p.setOpen(false)
  }

  useEffect(()=>{
    document.body.classList.toggle('no-scroll');

    return ()=>{
      document.body.classList.toggle('no-scroll');
    }
  },[])

  if(!p.open){return <></>}

  return (
    <div className="dialog-main-bg" onClick={() => p.setOpen(false)}>
      <div className='dialog-main' onClick={(e) => e.stopPropagation()}>
        <div className='dialog-border'>
          {p.innerComponenet(handleDialogClose)}
        </div>
      </div>
    </div>
  )
}

export default DialogComponent