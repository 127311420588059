import React, { useEffect, useState } from 'react'
import './menuPage.css';
import { useNavigate } from 'react-router-dom';
import SpikesDown from '../../components/SpikesDown';
import RulesComponent from '../../components/Rules/RulesComponent';
import { useEcard } from '../../providers/EcardProvider';
import { config } from '../../config';
import { useContractDataContext } from '../../providers/ContractDataProvider';
import { Bounce, toast } from 'react-toastify';
import { removeCookie } from '../../utils/utils';

function MenuPage() {
  // const { startSocketInstance } = useEcard();
    const { disconnectSocketInstance } = useEcard();
    const navigate = useNavigate();
    const [openRules, setOpenRules] = useState(false)
    const {contractData} = useContractDataContext();

    const copyText = React.useMemo(
      ()=>contractData.profile.username?`${config.thisUrl}?ref=${contractData.profile.username}`:``,
      [contractData]
    )

    const handleRulesOpen = () =>{
      setOpenRules(true)
    }

    // useEffect(()=>{
    //   if(contractData.profile.username){
    //     startSocketInstance()
    //   }
      
    // },[contractData])

    const handleCopyRefLink = () =>{
      navigator.clipboard.writeText(copyText)
            .then(() => {
             
                toast('Affiliate link copied to clipboard!', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  type : "success",
                  transition: Bounce,
                  });
            })
    }

    const handleLogout = () => {
      removeCookie("jwt")
      disconnectSocketInstance()
    }
    
  return (
    <>
    {openRules && <RulesComponent open={openRules} setOpen={setOpenRules} />}
    <div className='menu-main-div'>
        <SpikesDown text={"Main Menu"} />
        
        
        <div className="menu-items-menu">
            <p onClick={()=>navigate('/wallet')}>Wallet</p>
            <p onClick={()=>navigate('/ecard/lobby')}>Lobby</p>
            <p onClick={handleRulesOpen}>Game Rules</p>
            {/* <p onClick={()=>navigate('/ecard/leaderboard')}>Leaderboard</p> */}
        </div>
        {/* <img src={`${process.env.PUBLIC_URL}/cards2.png`} /> */}
        <div className='shape-cards'></div>
        <div>
          <input
          readOnly
          onClick={handleCopyRefLink}
          style={{marginLeft:25, marginTop:30}}
          value={copyText} />
        </div>
        <p className="logout-option" onClick={handleLogout}>LOGOUT</p>
    </div>
    
    </>
    
  )
}

export default MenuPage
