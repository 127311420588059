import { useState } from 'react'
import { ecardSocketEventNames } from '../../../socketCommunication/ecardSocket';
import { useEcard } from '../../../providers/EcardProvider';
import { convertHumanReadableToNumber, getValidEthValueFromAString } from '../../../web3/utils';
import DialogCloseButton from '../../../components/dialogs/DialogCloseButton';
import ArrowRightButton from '../../../components/ArrowRightButton';
import { roundTo2Deciamls } from '../../../utils/utils';

function CreateNewRoom(setDialogClose : ()=>void) {
    const {sendSocketMessage, ethPrice, walletBalance} = useEcard();
    const [betAmount, setBetAmount] = useState('0.001')
    const [errMsg, setErrMsg] = useState("");
    const handleBetChange = (event : any) =>{      
        setBetAmount(getValidEthValueFromAString(event.target.value));
    }

    const handelCreateNewRoom = ()=>{
      if(Number(betAmount) === 0){setErrMsg("Bet amount needs to be greater than 0!");return}
      if(!/^\d+(\.\d+)?$/.test(String(betAmount))){setErrMsg("Not a valid input number!");return}
      //check if user has available balance for this here and on the backend
      if(Number(walletBalance) < Number(betAmount)){setErrMsg("Not enough balance to create a room!");return}
      setErrMsg("")
      sendSocketMessage({eventName:ecardSocketEventNames.room.createNewRoom, data:[convertHumanReadableToNumber(betAmount)]})
      setDialogClose()
    }

  return (
    <div>
      <div className='center-container'>
      <p>Create a new Room </p>
        <span> Bet amount: </span>
        <span><input value={betAmount}  onChange={handleBetChange} size={8} /> ETH </span>
        <span className='opacity-c'> {roundTo2Deciamls(Number(betAmount) * Number(ethPrice))} $</span>
        {errMsg && <span style={{color:"red"}}>{errMsg}</span>}
      </div>
        
        <div className='row-container-end'>
          <span onClick={setDialogClose}><DialogCloseButton /></span>        
          <span onClick={handelCreateNewRoom}><ArrowRightButton text={"Create"} /></span>
        </div>
        {/* <span>{`[option for private roons - share room to other player]`}</span> */}
    </div>
  )
}

export default CreateNewRoom