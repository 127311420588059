import React, { useEffect, useState } from 'react'
import { config } from '../../../config'
import './game.css';
import { useEcard } from '../../../providers/EcardProvider';
import { ecardSocketEventNames } from '../../../socketCommunication/ecardSocket';
import TimerComponent from '../../../components/TimerComponent';
import { dispatchCustomEventListener } from '../../../utils/utils';

function EcardGame() {
  const {game, sendSocketMessage} = useEcard();
  const [timerKey, setTimerKey] = useState(0);

  useEffect(() => {
    const handler = (event:any) => {

      if (event.origin !== config.iframeGame.ecard) {
        return;
      }
        
        if(
          event?.data?.target === config.ecardIframe.targets.ecardGameManager &&
          event?.data?.data.name === config.ecardIframe.name
        ){
          if(event?.data?.data?.method === config.ecardIframe.message_methods.myCardPlaced && event?.data?.data?.params?.cardNo){
            sendSocketMessage({eventName:ecardSocketEventNames.game.playTurn, data:[event?.data?.data?.params?.cardNo, event?.data?.data?.params?.citizenNo || -1 ]})
          }
        }
    };
    window.addEventListener('message', handler);
    return () => {
      window.removeEventListener('message', handler);
    };
  }, []);

  //HANDEL DISPATCH
  const handleResetTimer = () =>{
    setTimerKey(p=>p+1)
  }

  const handleDialogObj = [
    {
      eventName: config.customDispatch.resetTimer,
      fallback: handleResetTimer,
    },
  ];

  const dialogListener = (event: any) => {
    //p:{eventName:string, fallback:(a:any)=>void}[], event:CustomEvent<any>
    dispatchCustomEventListener(handleDialogObj, event);
  };
  
  React.useEffect(() => {
    window.addEventListener("customPageEvent", dialogListener);
    return () => {
      window.removeEventListener("customPageEvent", dialogListener);
    };
  }, []);


  // React.useEffect(() => {
  //   sendSocketMessage({
  //     eventName: ecardSocketEventNames.room.pullRoomsData,
  //     data: {},
  //   });
  // }, []);

  return (
    <div>
      <div className="ecard-game-timer"><TimerComponent duration={30} reset={timerKey}/></div>
        <iframe
          id="ecardGameIframe"
          title={"Game"}
          src={`${config.iframeGame.ecard}?empFirst=${game?.emperorFirst===game?.inGameIndex?"true":"false"}`}
        ></iframe>
    </div>
  )
}

export default EcardGame