import DialogCloseButton from "./DialogCloseButton";

interface ErrorDialogProps {
  message: string;
  setDialogClose: () => void;
}

const ErrorDialog = ({ message, setDialogClose }: ErrorDialogProps) => {
  return (
    <div className="error-dialog-content center-container">
      <h3 className="dark-txt">Error</h3>
      <p>{message}</p>
      <span onClick={setDialogClose}><DialogCloseButton /></span>
      {/* <button onClick={setDialogClose}>Close</button> */}
    </div>
  );
};

export default ErrorDialog; 