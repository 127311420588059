import React, { useEffect, useState } from 'react';
import './WinnerDialog.css';

const kaijiQuotes = [
  "The world is a cruel place. You have to be prepared to fight for what you want.",
  "You can’t just sit back and wait for luck to come to you. You have to create your own opportunities.",
  "In the end, it’s not about winning or losing. It’s about how you play the game.",
  "Despair is a powerful motivator. It can push you to do things you never thought possible.",
  "The moment you think you’re safe is the moment you’re most vulnerable.",
  "A man who doesn’t take risks is a man who doesn’t live.",
  "You can’t escape your fate. You can only face it.",
  "Hope is a double-edged sword. It can lift you up or cut you down.",
  "The only way to truly lose is to give up.",
  "Every choice you make has consequences. Choose wisely.",
  "Fear is a natural part of life. It’s how you respond to it that defines you.",
  "Sometimes, the biggest gamble is trusting someone else.",
  "You can’t change the past, but you can shape your future.",
  "In a game of chance, the only certainty is uncertainty.",
  "The strongest weapon you have is your will to survive.",
  "Desperation can lead to incredible strength.",
  "You have to be willing to lose everything to gain something.",
  "Life is a gamble, and you have to play your cards right.",
  "The thrill of the game is what makes it all worthwhile.",
  "Sometimes, the greatest victory is simply standing up again after a fall."
];

interface ComponentProps {
    type: "winner" | "loser";
    close: () => void;
}

function WinnerDialog(props: ComponentProps) {
    const [animate, setAnimate] = useState(false);
    const [subMessage, setSubMessage] = useState("");

    useEffect(() => {
        // Trigger animation after component mount
        setTimeout(() => setAnimate(true), 100);
        setSubMessage(kaijiQuotes[Math.floor(Math.random() * kaijiQuotes.length)]);
    }, []);

    const message = props.type === "winner" 
        ? "Congratulations! You Won! 🎉" 
        : "You have lost the game! 🤬";
    
    // const subMessage = props.type === "winner"
    //     ? "The moment you think you’re safe is the moment you’re most vulnerable!"
    //     : "Despair is a powerful motivator. It can push you to do things you never thought possible.";


    return (
        <div className="dialog-overlay">
            <div className={`winner-dialog ${animate ? 'animate' : ''} ${props.type === "winner" ? 'winner' : 'loser'}`}>
                <div className="dialog-content">
                    <h1 className="dialog-title">{message}</h1>
                    <p className="dialog-subtitle italic">{subMessage}</p>
                    
                    <div className="dialog-decoration">
                        {props.type === "winner" ? (
                            <div className="trophy-animation">🏆🎲</div>
                        ) : (
                            <div className="star-animation">🎭🎲</div>
                        )}
                    </div>

                    <button 
                        onClick={props.close} 
                        className="dialog-button"
                        type="button"
                    >
                        Back to Lobby
                    </button>
                </div>
            </div>
        </div>
    );
}

export default WinnerDialog;