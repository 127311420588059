import { useEcard } from "../../../../providers/EcardProvider";
import { ecardSocketEventNames } from "../../../../socketCommunication/ecardSocket";
import DialogCloseButton from "../../../../components/dialogs/DialogCloseButton";

interface BotWagerDialogProps {
  wagers: string[];
  setDialogClose: () => void;
}

const BotWagerDialog = ({ wagers, setDialogClose }: BotWagerDialogProps) => {
  const { sendSocketMessage } = useEcard();

  const handleWagerSelect = (wager: string) => {
    sendSocketMessage({
      eventName: ecardSocketEventNames.room.joinVsBot,
      data: [wager],
    });
    setDialogClose();
  };

  return (
    <div className="bot-wager-dialog center-container">
      <h3 className="dark-txt">Choose a wager</h3>
      <div className="bot-wager-options">
        {wagers.map((wager, index) => (
          <button 
            key={index} 
            className="hoverBtn mainBtn"
            onClick={() => handleWagerSelect(wager)}
          >
            {wager} ETH
          </button>
        ))}
      </div>
      <span onClick={setDialogClose}>
        <DialogCloseButton />
      </span>
    </div>
  );
};

export default BotWagerDialog; 