import { ethers } from "ethers";

export const convertHumanReadableToNumber = (n: string) => {
  return Number(n) * 1000000000000000000;
};

export const convertBigIntAsStringToReadable = (n: string) => {
  return ethers.formatEther(ethers.toBigInt(n));
};

export const getValidEthValueFromAString = (n: string) => {
  let value = String(n || "0");
  value = value.replace(/[^0-9.]/g, "");
  value = value.replace(/(\..*?)\..*/, '$1');
  value = value.replace(/^0+(?=\d)/, "");
  if (value.startsWith(".")) {
    value = "0" + value;
  }
  const parts = value.split(".");
  if (parts.length > 1 && parts[1].length > 18) {
    value = `${parts[0]}.${parts[1].slice(0, 18)}`;
  }
  return value;
};
