import { ConnectButton as RainbowConnectButton } from '@rainbow-me/rainbowkit';
import './connectButton.css';
import { useState, useEffect } from 'react';
import DialogComponent from '../../../components/dialogs/DialogComponent';
import CreateNewUserDialog from '../dialog/CreateNewUserDialog';
import { useContractDataContext } from '../../../providers/ContractDataProvider';
import LoaderComponent from '../../../components/LoaderComponent';
import { useEcard } from '../../../providers/EcardProvider';
import { useAccount } from 'wagmi';
import { Bounce, toast } from 'react-toastify';
import { config } from '../../../config';

export const ConnectButton = () => {
  const [createNewUserDialogOpened, setCreateNewUserDialogOpened] = useState(false);
  const [needsSignature, setNeedsSignature] = useState(false);
  const { contractData, signer } = useContractDataContext();
  const { startSocketInstance } = useEcard();
  const { address } = useAccount();

  useEffect(() => {
    const checkExistingToken = async () => {
      const jwt = document.cookie
        .split('; ')
        .find(row => row.startsWith('jwt='))
        ?.split('=')[1];

      if (jwt) {
        try {
          await startSocketInstance();
          setNeedsSignature(false);
        } catch (error) {
          setNeedsSignature(true);
        }
      } else {
        setNeedsSignature(true);
      }
    };

    if (contractData.profile.username) {
      checkExistingToken();
    }
  }, [contractData.profile.username]);

  const handleSignMessage = async () => {
    try {
      // Get message to sign
      const response = await fetch(`${config.restApiEndpoint}/api/action/messageToSign/${address}`);
      const messageData = await response.json();
      
      if (!messageData.success) {
        throw new Error('Failed to get message to sign');
      }

      // Sign the message
      const signature = await signer?.signMessage(messageData.data);
      
      if (!signature) {
        throw new Error('Failed to sign message');
      }

      // Send signed message
      const signedResponse = await fetch(`${config.restApiEndpoint}/api/action/messageToSign/${address}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ signature }),
      });

      const tokenData = await signedResponse.json();
      
      if (!tokenData.token) {
        throw new Error('No token received');
      }

      // Save JWT as cookie with secure settings
      document.cookie = `jwt=${tokenData.token}; path=/; max-age=2592000; secure; SameSite=Strict`; // 30 days

      // Start socket instance
      await startSocketInstance();
      setNeedsSignature(false);

      toast('Successfully authenticated!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        type: "success",
        transition: Bounce,
      });

    } catch (error) {
      setNeedsSignature(true);
      toast(error instanceof Error ? error.message : 'Authentication failed', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        type: "error",
        transition: Bounce,
      });
    }
  };

  return (
    <RainbowConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === 'authenticated');

        return (
          <>
            <div
              {...(!ready && {
                'aria-hidden': true,
                style: {
                  opacity: 0,
                  pointerEvents: 'none',
                  userSelect: 'none',
                },
              })}
            >
              {(() => {
                if (!connected) {
                  return (
                    <button onClick={openConnectModal} type="button" className='connectButton-style hoverBtn'>
                      <span className='skew-fix'>Connect Wallet</span>
                    </button>
                  );
                }
                if (chain.unsupported) {
                  return (
                    <button onClick={openChainModal} type="button">
                      Wrong network
                    </button>
                  );
                }
                return (
                  <div style={{ display: 'flex', gap: 12 }}>
                    {contractData.profile.profileCreationFee ?
                      <button
                        onClick={() => {
                          contractData.profile.username ?
                            handleSignMessage() :
                            setCreateNewUserDialogOpened(true)
                        }}
                        type="button"
                        className='connectButton-style hoverBtn dots-blue-bg'
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <span className='skew-fix' style={{ width: "100%" }}>
                          {contractData.profile.username ? 
                            (needsSignature ? "SIGN A MESSAGE" : "CONNECTING...") : 
                            "CREATE ACCOUNT"}
                        </span>
                      </button> :
                      <span>
                        <LoaderComponent size={30} />
                      </span>
                    }
                  </div>
                );
              })()}
            </div>
            {createNewUserDialogOpened && (
              <DialogComponent
                innerComponenet={CreateNewUserDialog}
                open={createNewUserDialogOpened}
                setOpen={setCreateNewUserDialogOpened}
              />
            )}
          </>
        );
      }}
    </RainbowConnectButton.Custom>
  );
};
