export const sleep = (ms:number) => new Promise(resolve => setTimeout(resolve, ms))

export const roundTo2Deciamls = (n:number) => Math.round( n * 100) / 100

export const fetchEthPrice = async () =>{
    const response = await fetch('https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT');
    const data = await response.json();
    return data.price;
  }

export const dispatchCustomEvent = (eventName:string, message:string,) =>{
  // Triggering the custom event
  const eventData = {eventName, message};
  const customEvent = new CustomEvent('customPageEvent', { detail: eventData });
  window.dispatchEvent(customEvent);

}

export const dispatchCustomEventListener = (p:{eventName:string, fallback:(a:any)=>void}[], event:CustomEvent<any>) =>{

    for(const o of p){
      if(event.detail.eventName === o.eventName){
        return o.fallback(event.detail.message);
      }
    }
}

// window.addEventListener('customPageEvent', dispatchCustomEventListener);
// window.removeEventListener('customPageEvent', dispatchCustomEventListener);



// // Listen for the custom event and access the data
// element.addEventListener('my-event', function(event) {
//   console.log('Event data:', event.detail);
// });



// // Listening for the custom event
// window.addEventListener('myCustomEvent', function(event) {
//   const receivedData = event.detail;
//   console.log('Received data:', receivedData);
//   // You can now use the receivedData in this part of the code
// });


export const getCookie = (name:string) =>{
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    //@ts-ignore
    return parts.pop().split(';').shift();
  } else {
    return ""
  }
}

export const removeCookie = (name: string) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; secure; SameSite=Strict`;
}