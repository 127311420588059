import { useState } from 'react'
import { useEcard } from '../../../providers/EcardProvider';
import DialogCloseButton from '../../../components/dialogs/DialogCloseButton';
import ArrowRightButton from '../../../components/ArrowRightButton';
import { roundTo2Deciamls } from '../../../utils/utils';
import { useAccount } from 'wagmi';
import { convertHumanReadableToNumber, getValidEthValueFromAString } from '../../../web3/utils';
import { useContractDataContext } from '../../../providers/ContractDataProvider';
import { handleWithdrawApi } from '../../../utils/api/actions';

function WithdrawDialog(setDialogClose : ()=>void) {
    const {sendSocketMessage, ethPrice, walletBalance} = useEcard();
    const [amount, setAmount] = useState('0.001')
    const [errMsg, setErrMsg] = useState("");
    const account = useAccount();
    const { signer } = useContractDataContext()
    const [loader, setLoader] = useState(false)
    
    const handleBetChange = (event : any) =>{
      setAmount(getValidEthValueFromAString(event.target.value));
    }

    const handelWithdrawDialog = ()=>{
      if(Number(amount) === 0){setErrMsg("Bet amount needs to be greater than 0!");return}
      if(!/^\d+(\.\d+)?$/.test(String(amount))){setErrMsg("Not a valid input number!");return}

      const toSign = {
        site: "gamble.haus",
        address: account.address,
        action : "WITHDRAW",
        amount : (convertHumanReadableToNumber(amount)),
        timestamp : Date.now()
      }
      const toSignJson = JSON.stringify(toSign)
      signer?.signMessage(toSignJson).then(async (signature : any) => {
  
        setLoader(true)
        const data = await handleWithdrawApi(signature, toSignJson)
        if(data.message){
          setErrMsg(data.message)
        }else{
          setErrMsg("")
          setDialogClose()
        }
     
        setLoader(false)
        
      })
      
    }

    const handleBalanceClick = () =>{
      setAmount(walletBalance)
    }
  return (
    <div>
      <div className='center-container'>
      <p>Withdraw </p>
      {loader && <p>loading...</p>}
        <span style={{backgroundColor:"#acf591", padding:5, margin:15, cursor:"pointer"}}  onClick={handleBalanceClick}>Balance : {walletBalance} ETH</span>
        {/* TODO when balance : click add balance to the field */}
        <span><input value={amount}  onChange={handleBetChange} size={8} /> ETH </span>
        <span className='opacity-c'> {roundTo2Deciamls(Number(amount) * Number(ethPrice))} $</span>
        {errMsg && <span style={{color:"red"}}>{errMsg}</span>}
      </div>
        
        <div className='row-container-end'>
          <span onClick={()=>!loader && setDialogClose()}><DialogCloseButton /></span>        
          <span onClick={handelWithdrawDialog}><ArrowRightButton text={"Withdraw"} /></span>
        </div>
        {/* <span>{`[option for private roons - share room to other player]`}</span> */}
    </div>
  )
}

export default WithdrawDialog